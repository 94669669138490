import type { discoverType } from '@/types/discover'
import axios from 'axios'

export default class {
  static async fetchIPAddressByMacAddress({
    macAddress = undefined,
  }: {
    macAddress?: string
  } = {}) {
    const response = await axios.get<discoverType>(
      `/Fitbike/Discovery?MacAddress=${macAddress}`,
    )

    return response.data.Results?.[0] || null
  }
}
