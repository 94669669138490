import { defineStore } from 'pinia'
import { antDevice, bleDevice, deviceType } from '@/types/device'

export const useDeviceStore = defineStore('deviceStore', {
  state: (): {
    antDevices: Array<antDevice>
    bleDevices: Array<bleDevice>
  } => ({
    antDevices: [],
    bleDevices: [],
  }),
  getters: {
    getAntDevicesByType:
      (state) =>
        (deviceType: deviceType): Array<antDevice> => {
          return state.antDevices?.filter((x) => x.type === deviceType) ?? []
        },
    getBleDevicesByType:
      (state) =>
        (deviceType: deviceType): Array<bleDevice> => {
          return state.bleDevices?.filter((x) => x.type === deviceType) ?? []
        },
  },
  actions: {
    updateAntList(antDevices: Array<antDevice>) {
      this.antDevices = antDevices
    },
    updateBleList(bleDevices: Array<bleDevice>) {
      this.bleDevices = bleDevices
    },
  },
})
