export enum deviceType {
  resistanceWheel = 'resistanceWheel',
  heartRate = 'heartRate',
  xyTool = 'xyTool',
}

export enum deviceProtocol {
  ANT = 'ANT',
  BLE = 'BLE',
}

export interface baseDevice {
  name: string
  id: string
  type: string
  lastSeen: number
}

export interface antDevice extends baseDevice {}

export interface bleDevice extends baseDevice {}
